/**
 * Global
 *
 * !default Flag
 * When building a library, a framework, a grid system or any piece of Sass
 * that is intended to be distributed and used by external developers,
 * all configuration variables should be defined with the !default flag
 * so they can be overwritten.
 */


@font-face {
    font-family: "Noto Sans";
    src:url("../assets/fonts/NotoSans-Light.ttf") format("truetype");
    font-style:normal;font-weight:200;
}

@font-face {
    font-family: "Noto Sans";
    src:url("../assets/fonts/NotoSans-Regular.ttf") format("truetype");
    font-style:normal;font-weight:300;
}

@font-face {
    font-family: "Noto Sans";
    src:url("../assets/fonts/NotoSans-SemiBold.ttf") format("truetype");
    font-style:normal;font-weight:400;
}

 @font-face {
    font-family: "Noto Sans Thai";
    src:url("../assets/fonts/NotoSansThai-Light.ttf") format("truetype");
    font-style:normal;font-weight:200;
}

@font-face {
    font-family: "Noto Sans Thai";
    src:url("../assets/fonts/NotoSansThai-Regular.ttf") format("truetype");
    font-style:normal;font-weight:300;
}

@font-face {
    font-family: "Noto Sans Thai";
    src:url("../assets/fonts/NotoSansThai-SemiBold.ttf") format("truetype");
    font-style:normal;font-weight:400;
}
// Fonts
$font-family: 'Noto Sans Thai', "Noto Sans", sans-serif;
$font-family-base: $font-family;
$font-weight-base: 300;
$font-size-base: 1rem !default;
$line-height-base: 1.5 !default;
$body-color: #222 !default;

// Transition
$transition-base: all .2s ease-in-out !default;


.no-break{
    white-space: nowrap;
}

.gold{
    color: #b58d58;
}
.bold{
    font-weight: 400;
}

a, button{
    outline: none !important;
}

#debugger{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #face00;
    font-size: 12px;
    font-family: monospace;
    line-height: 1;
    padding: 3px;
    font-weight: bold;
}