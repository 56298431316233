.page-bus{
    .banner{
        min-height: 45vw;
        background: url(../assets/images/bus/banner.jpg) center center no-repeat;
        background-size: cover;

        @include media-breakpoint-up(lg){
            min-height: 30vw;
        }
    }

    .intro{
        padding-top: 45px;
        padding-bottom: 45px;
        text-align: center;

        @include media-breakpoint-up(lg){
            padding-left: 4rem;
            padding-right: 4rem;
            min-height: 60vh;
        }

        .header{
            color: #b58d58;
            font-size: 2rem;
            position: relative;
            width: fit-content;
            padding-left: 1rem;
            padding-right: 1rem;
            margin: 0 auto 1rem;

            &:before{
                content: '';
                background: url(../assets/images/bus/bus-header-left.svg) right center no-repeat;
                background-size: contain;
                height: 0.66rem;
                width: 3.916rem;
                position: absolute;
                display: block;
                top: calc(50% - .33rem);
                right: 100%;
            }

            &:after{
                content: '';
                background: url(../assets/images/bus/bus-header-right.svg) left center no-repeat;
                background-size: contain;
                height: 0.66rem;
                width: 3.916rem;
                position: absolute;
                display: block;
                top: calc(50% - .33rem);
                left: 100%;
            }
        }

        .text{
            color: #646464;
        }

        .btn-download{
            display: block;
            color: #fff;
            text-decoration: none;
            background: #193b6b;
            font-weight: 400;
            padding: .75rem 1.5rem;
            width: fit-content;
            margin: 0 auto;
        }
    }

    .car-selector{
        background: url(../assets/images/bus/selector-bg.jpg) center center no-repeat;
        background-size: cover;
        // border-bottom: 8px solid #b58d58;
        padding-top: 2.5rem;
        padding-bottom: 2rem;

        @include media-breakpoint-up(lg){
            background: #1a3c6c;
            padding-bottom: 0;
        }

        .title{
            color: #fff;
            margin-bottom: 2rem;
            font-size: 1.5625rem;
        }

        .nice-select{
            @extend .d-flex, .align-items-center;
            border-radius: 0;
            border: none;
            background: #b58d58;
            color: #fff;
            width: calc(100% - 90px);
            margin-bottom: 2rem;
            box-sizing: border-box;
            height: 48px;
            margin-left: 45px;
            margin-right: 45px;

            @include media-breakpoint-up(lg){
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                height: 60px;
            }

            &.selected{
                border: 1px solid #fff;
            }
            
            &:after{
                border-bottom-color: #fff;
                border-right-color: #fff;
                width: 10px;
                height: 10px;
                margin-top: -8px;
            }

            .list{
                border-radius: 0;
                border: 0;
                margin-top: 0;
                color: #b58d58;
                width: 100%;
                z-index: 20;

                li{
                    @extend .d-flex, .align-items-center;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    margin: 0;
                    border: 1px solid #b58d58;
                    height: 48px;

                    & + li{
                        border-top: none;
                    }
                }
            }
        }
    }

    .backTop{
        background: #b58d58;
        height: 10px;
        top: 64px;
        position: sticky;
        width: 100%;
        z-index: 10;

        @include media-breakpoint-up(lg){
            top: 76px;
        }

        &__link{
            background: #b58d58;
            border-radius: 1rem;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            color: #fff;
            text-decoration: none;
            top: 0;
            right: 0;
            padding: .75rem 1rem;
            position: absolute;
        }
    }

    .car-gallery{
        background: #f2f2f2;
        padding-top: 75px;
        padding-bottom: 50px;
        opacity: 1;
        transition: all .5s;

        &.loading{
            opacity: 0;
        }

        &-slider{
            &-item{
                background: center center no-repeat;
                background-size: contain;
                padding-bottom: 52.88%;
                position: relative;
                width: calc(100% -  4px);
                margin: 0 auto; 

                &-link{
                    background: center center no-repeat url(../assets/images/icons/fullscreen.svg);
                    background-size: contain;
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    height: 30px;
                    width: 30px;
                }
            }

            .slick{
                &-arrow{
                    position: absolute;
                    top: calc(50% - .75rem);
                    color: #000;
                    background: transparent;
                    border: none;
                    z-index: 10;
                    font-size: 1.5rem;
                    line-height: 1;
                    outline: none;
                    cursor: pointer;

                    @include media-breakpoint-up(lg){
                        font-size: 2rem;
                        top: calc(50% - 1rem);
                    }

                    &-prev{
                        left: -2rem;
                        @include media-breakpoint-up(lg){
                            left: -3rem;
                        }
                    }

                    &-next{
                        right: -2rem;
                        @include media-breakpoint-up(lg){
                            right: -3rem;
                        }
                    }
                }
                &-track{
                    @extend .d-flex, .align-items-stretch;
                }
            }
        }

        &-text{
            text-align: center;
            color: #636466;
            margin-top: 50px;

            &-title{
                font-size: 2rem;
                font-weight: 300;
            }

            &-info{
                border: 1px solid #b58d58;
                border-left: none;
                border-right: none;
                padding: 2rem 1rem;
                margin: 25px auto;

                .gold{
                    color: #b58d58;
                }
            }
        }
    }

    .car-plan{
        opacity: 1;
        transition: all .5s;

        @include media-breakpoint-up(lg){
            background: linear-gradient(to right, rgba(230,218,201,1) 0%, rgba(230,218,201,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
        }

        & > .container{
            max-width: 100%;
            @include media-breakpoint-up(lg){
                max-width: 1500px;
            }
        }

        &.loading{
            opacity: 0;
        }

        .plan-wrapper{
            background: #e6dac9;
            padding-top: 25px;
            padding-bottom: 25px;
        }
        .plan{
            @extend .d-flex, .align-items-center;
            padding-top: 50px;
            padding-bottom: 50px;
            margin: 15px;
            width: calc(100% - 30px);

            @include media-breakpoint-up(md){
                margin: 50px;
                width: calc(100% - 100px);
                padding-top: 50px;
                padding-bottom: 50px;
            }
            
            
            &-image{
                @extend .mx-auto;
                display: block;
            }
        }

        .facility{
            padding: 50px 25px;
            margin: 0 auto;

            &-item{
                @extend .d-flex, .align-items-center;

                &-logo{
                    display: inline-block;
                    width: 65px;
                    height: 65px;
                    margin-right: 2rem;
                    background: center center no-repeat;
                    background-size: contain;

                    &.logo-usb{
                        background-image: url(../assets/images/icons/usb.png);
                        background-size: auto 50%;
                    }

                    &.logo-seatbelt{
                        background-image: url(../assets/images/icons/seatbelt.png);
                    }

                    &.logo-tv{
                        background-image: url(../assets/images/icons/tv.png);
                        background-size: auto 50%;
                    }

                    &.logo-hdmi{
                        background-image: url(../assets/images/icons/hdmi.png);
                    }

                    &.logo-karaoke{
                        background-image: url(../assets/images/icons/karaoke.png);
                        background-size: auto 50%;
                    }

                    &.logo-mic{
                        background-image: url(../assets/images/icons/mic.png);
                        background-size: auto 50%;
                    }

                    &.logo-airplay{
                        background-image: url(../assets/images/icons/airplay.png);
                        background-size: auto 50%;
                    }

                    &.logo-cd{
                        background-image: url(../assets/images/icons/cd.png);
                        background-size: auto 50%;
                    }

                    &.logo-bluetooth{
                        background-image: url(../assets/images/icons/bluetooth.png);
                        background-size: auto 50%;
                    }
                }

                &-text{
                    display: inline-block;
                }
            }

            @include media-breakpoint-up(lg){
                padding: 50px;
            }

            .btn-download{
                background: #b58d58;
                color: #fff;
                text-decoration: none;
                display: inline-block;
                padding: .75rem 1.5rem;
                font-weight: 400;

                i.fas{
                    transition: all .4s;
                }
                &:hover, &:active{
                    i.fas{
                        transform: translateX(100%);
                    }
                }
            }
        }
    }

    .services{
        background: #f2f2f2;
        padding-top: 0;
        padding-bottom: 10px;

        .service__title{
            color: #093c71;
            font-size: 1.375rem;
            font-weight: 400;
            padding-left: 5%;
            margin: 0 auto;
            padding-bottom: 20px;
        }

        .service-van {
            padding-top: 40px !important;
            padding-bottom: 0 !important;
            position: relative !important;
            height: 100% !important;

            &--withBorder{
                padding-top: 0 !important;
                padding-bottom: 40px !important;
                &:before{
                    display: none !important;
                    
                }
                @include media-breakpoint-up(md){  
                    padding-top: 66px !important;
                }
            }

            .service__title{
                padding-bottom: 0px !important;
            }
        }

        .service{
            padding-top: 40px;
            padding-bottom: 40px;
            position: relative;
            height: 100%;

            &--withBorder{
                &:before{
                    background: #c0c0c0;
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 5%;
                    height: 1px;
                    width: 90%;        //landscape line
                    

                    @include media-breakpoint-up(md){  
                        top: 40px;
                        left: -15px;
                        height: calc(100% - 80px);
                        width: 1px;
                    }
                }
            }

            &__item{
                @extend .d-flex, .align-items-center;
                padding-top: .5rem;
                padding-bottom: .5rem;
                margin: 0 auto;
                width: 90%;

                @include media-breakpoint-up(lg){
                    width: 80%;
                }
                

                &__icon{
                    background: center center no-repeat;
                    background-size: auto 70%;
                    display: inline-block;
                    height: 35px;
                    width: 75px;

                    &--cup{background-image: url(../assets/images/icons/f-cup.svg);}
                    &--san{background-image: url(../assets/images/icons/f-san.svg);}
                    &--hotwater{background-image: url(../assets/images/icons/f-hotwater.svg);}
                    &--pillow{background-image: url(../assets/images/icons/f-pillow.svg);}
                    &--blanket{background-image: url(../assets/images/icons/f-blanket.svg);}
                    &--consult{background-image: url(../assets/images/icons/f-consult.svg);}
                    &--coffee{background-image: url(../assets/images/icons/f-coffee.svg);}
                    &--snack{background-image: url(../assets/images/icons/f-snack.svg);}
                    &--ice{background-image: url(../assets/images/icons/f-ice.svg);}
                    &--wifi{background-image: url(../assets/images/icons/f-wifi.svg);}
                    &--police{background-image: url(../assets/images/icons/f-police.svg);}
                    &--specific{background-image: url(../assets/images/icons/f-specific.svg);}
                }
            }
        }
    }

    .technical{
        padding-top: 50px;
        padding-bottom: 50px;

        .header{
            color: #093c71;
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 1.5rem;
        }

        &__item{
            width: 100%;
            margin-bottom: 1rem;

            &__header{
                background: #f2f2f2 right 1.5rem center no-repeat url(../assets/images/icons/arrow-right.png);
                background-size: auto 1.2rem;
                display: block;
                color: #636466;
                font-size: 1.375;
                font-weight: 600;
                padding: 1rem 2rem;
                text-decoration: none !important;
                transition: background-color .4s;

                &[aria-expanded=true]{
                    background-color: #b58d58;
                    background-size: 1.2rem auto;
                    background-image: url(../assets/images/icons/arrow-down.png);
                    color: #fff;
                }
            }

            &__text{
                background: #f2f2f2;
                color: #636466;
                font-size: 1.125rem;
                font-weight: 400;
                padding: 50px 20px;

                @include media-breakpoint-up(lg){
                    padding: 55px 100px;
                }
            }
        }
    }
}