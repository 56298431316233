/**
 * Navbar
 */

#main-nav{
  background: #031e44;
  position: sticky;
  top: 0;
  z-index: 100;

  .navbar{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: 100%;
    
    @include media-breakpoint-up(lg){
      height: 76px;
    }

    .no-padding {
      padding-left: 0;
      padding-right: 0;
      
    }

    .navbar-toggler{
      color: #b58d58;
      outline: none;
      padding-left: 0;
      padding-right: 0;

      .navbar-toggler-bar{
        width: 25px;
        height: 3px;
        background-color: #b58d58;
        display: block;

        &+.navbar-toggler-bar{
          margin-top: 5px;
        }
      }
    }

    &-brand{
      height: 64px;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg){
        height: 76px;
        display: flex;
        align-items: center;
      }

      .logo{
        width: 187px;
        height: 22px;

        @include media-breakpoint-up(lg){
          width: 255px;
          height: 30px;
        }
      }
    }

    &-collapse{

      @include media-breakpoint-up(lg){
        margin-top: 0;
      }

      &.show{
        margin-top: 15px;
        padding-top: calc(3rem - 45px);
        padding-bottom: 3rem;
        height: calc(100vh - 60px);
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg){
          padding: 0;
          margin-top: 0;
        }

        
      }
    }

    .nav{
      display: flex;
      .nav-item{
        text-align: center;
        margin-bottom: 0;
        // width: 100%;     //effect to edge

        @include media-breakpoint-up(lg){
          width: fit-content;
        }
        // @include media-breakpoint-up(sm){
        //   width: 100%;
        // }

        &.active{
          .nav-link{
            font-weight: 400;
          }
        }

        .nav-link{
          color: #fff;
          text-decoration: none;
          transition: all .4s;
          // padding: .9rem 1rem;

          &:hover, &:focus{
            color: #b58d58;
          }
        }
      }
    }
  // }

    .lang-selector{
      @extend .justify-content-center;
      color: #fff;

      a{
        outline: none;
      }

      > .nav-link{
        background: #425673;
        padding-left: .75rem;
        padding-right: .75rem;
        text-align: left;
        display: inline-block;
        right: 0;
      }

      #dropdownLangOption{
        @extend .mx-auto;
        border: 0;
        border-radius: 0;
        margin-top: 0;
        padding: 0;
        width: fit-content;
        min-width: 36px;
        left: 50%;
        transform: translateX(-50%);
        margin-top: .5rem;

        @include media-breakpoint-up(lg){
          min-width: unset;
          margin-top: 0;
        }

        .dropdown-item{
          min-width: 20px;
          width: 100%;
          padding: .5rem;
          color: #031e44;
          display: block;

          &:hover, &:active{
            background: #ebebeb;
            font-weight: bold;
          }
        }
      }

      .lang-icon{
        width: 20px;
        height: 20px;
        @include media-breakpoint-up(lg){
          margin-right: .5rem;
        }
      }

      .lang-label{
        display: none;

        @include media-breakpoint-up(lg){
          display: inline-block;
          padding-right: .5rem;
        }
      }

      .nav-item{
        .nav-link{
          padding: 0;
        }
      }

      &-mobile{
        margin-right: 26px;

        .nav-item.dropdown{
          .nav-link{
            &:after{
              display: none;
            }
          }
        }
      }

      // &-desktop{
      //   background: #425673;
      //   padding: .5rem 1rem;
      // }
    }
  }
}
.navbar-bottom-bar{
  position: relative;
  width: 100%;
  height: 15px;
  background: #193b6b;
  border-bottom: 4px solid #b58d58;

  @include media-breakpoint-up(lg){
    height: 30px;
    border-bottom: 8px solid #b58d58;
  }
}