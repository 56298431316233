.page-customer{
    .clients{
        background-size: auto 50%;
        background-color: #fff;

        @include media-breakpoint-up(sm){
            background-size: auto 45%;
        }

        @include media-breakpoint-up(md){
            background-size: auto 35%;
        }
        @include media-breakpoint-up(lg){
            background-size: 100% auto;
            background-position: center top -10vw; 
        }

        .people{
            margin-top: 80px;
        }

        .logo-wrapper{
            .logo{
                margin: 50px auto;
                padding-bottom: 0;
                flex-wrap: wrap;
                width: unset;

                &-item{
                    width: 25%;
                    display: block;
                    margin: 0;
                    padding: 0;
                    flex: 1 0 25%;
                    padding: 1rem;

                    @include media-breakpoint-up(lg){
                        flex: 1 0 12.5%;
                    }

                    &-image{
                        @extend .w-100, .img-fluid;
                    }
                }
            }
        }
    }
}