/**
 * Footer
 */

#footer{
    background: #093c71;
    color: #fff;
    padding-top: 50px;

    .contact-info{
      padding-bottom: 1rem;

      @include media-breakpoint-up(lg){
        padding-bottom: 45px;
      }

      .line{
        background-color: #bf955a;
        height: 2px;
        width: 100%;
        margin: 14px 0;
      }

      .logo{
        width: 80px;
        display: block;
        margin: 0 auto;
      }

      .phone{
        &-item{
          padding: .25rem 0;
          display: block;
          color: #fff;
          text-decoration: none;
          background: url(../assets/images/icons/phone-local.svg) left center no-repeat;
          background-size: auto 1rem;
          padding-left: 1.5rem;
          line-height: 28px;

          &--intl{
            background-image: url(../assets/images/icons/phone.svg);
          }
          
          font-size: 1rem;
          @include media-breakpoint-up(lg){
            font-size: .9rem;
          }
        }
      }

      .social{
        margin-top: 40px;

        @include media-breakpoint-up(lg){
          margin-top: 0;
        }

        &-item{
          color: #fff;
          text-decoration: none;
          padding: .25rem 0;
          display: block;
          line-height: 28px;

          &-icon{
            height: 1rem;

            @include media-breakpoint-up(lg){
              margin-right: .5rem;
            }
          }

          &-label{
            @extend .d-none, .d-lg-inline-block;
            line-height: 28px;
            font-size: .9rem;
          }
        }
      }
    }

    .copyright{
      background: #031e44;
      color: #bf955a;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: #bf955a 2px solid;
      border-bottom: #bf955a 7px solid;
    }
}