/**
 * List Inline
 */

.list-inline {
  list-style: none;
  padding-left: 0;
  &__item {
    display: inline-block;
    margin-right: 1rem;
  }
}
