.page-contact{
    #map{
        width: 100%;
        height: 275px;
        background: #ccc;
        margin-bottom: 25px;

        @include media-breakpoint-up(lg){
            margin-bottom: 50px;
        }
    }

    .info{
        .content-wrapper{
            background: url(../assets/images/contact/callcenter.png) no-repeat;
            background-position: right 15px top;
            background-size: 45% auto;
            padding-top: 30%;

            @include media-breakpoint-up(md){
                padding-top: 0;
                background-size: 25% auto;
            }

            .header{
                font-size: 2rem;
                color: #b58d58;
                font-weight: 300;
            }

            .text{
                color: #b58d58;
            }

            .line{
                height: 1px;
                background: #b58d58;
                margin: 1.75rem auto;
            }

            .office-hour{
                color: #646464;
            }

            .telephone{
                color: #646464;
                display: flex;
                flex-wrap: wrap;

                &-item{
                    display: inline-block;
                    color: #646464;
                    text-decoration: none;
                    padding-left: 2rem;
                    background: url(../assets/images/icons/phone-local-gold.svg) left center no-repeat;
                    background-size: auto 80%;
                    margin-bottom: .75rem;
                    flex: 1 0 50%;

                    &--intl{
                        background-image: url(../assets/images/icons/phone-gold.svg);
                    }

                    @include media-breakpoint-up(lg){
                        flex: 1 0 18%;
                        margin-bottom: 0;
                    }
                }
            }

            .social{
                display: flex;
                flex-wrap: wrap;

                &-item{
                    color: #646464;
                    text-decoration: none;
                    padding: .25rem 0;
                    display: inline-block;
                    flex: 1 0 100%;
                    font-size: 1rem;
                    

                    @include media-breakpoint-up(lg){
                        flex: 1 0 50%;    
                    }

                    &-icon{
                        margin-right: .5rem;
                        width: 1rem;
                    }
                }
            }

            .address{
                color: #646464;
            }

            .promptpay{
                @extend .d-flex, .align-items-start, .flex-column, .flex-lg-row;

                &__qr{
                    text-align: center;
                    padding: 1rem 2rem 3rem;
                    width: 100%;

                    @include media-breakpoint-up(lg){
                        padding: 0;
                        width: 120px;
                    }

                    &__visual{
                        max-width: 180px;
                        margin: 0 auto;

                        @include media-breakpoint-up(lg){
                            max-width: 100%;
                        }
                    }
                }

                &__text{
                    @include media-breakpoint-up(lg){
                        padding-left: 2rem;
                    }

                    &__logo{
                        display: block;
                        height: 35px;
                        margin-bottom: 1rem;
                    }

                    &__highlight{
                        color: #b58d58;
                        font-size: 1.25;
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    &__account{
                        color: #646464;
                    }
                }
            }
        }
    }

    .more-action{
        margin-top: 1.5rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
        background: #f3f3f3;
        font-weight: 300;
        
        .text{
            color: #b58d58;
            line-height: 2; 
        }

        .btn-action{
            background: #b58d58;
            color: #fff;
            text-decoration: none;
            display: inline-block;
            width: 100%;
            text-align: center;
            padding-top: .75rem;
            padding-bottom: .75rem;
            line-height: 1;
            margin-bottom: 1rem;
        }
    }
}