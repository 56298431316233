.page-news-detail{
    background: url(../assets/images/news/bg-news-detail.jpg) center top no-repeat;
    background-size: cover;
    
    .content-wrapper{
        border-top: 7px solid #b58d58;
        background: #fff;
        padding-bottom: 50px;
  
        .btn-close{
            display: inline-block;
            margin-top: 1rem;
            margin-bottom: 1rem;
            color: #b58d58;
            text-decoration: none;
        }

        .gallery{
            &-item{
                &-image{
                    @extend .w-100, .img-fluid;
                }
            }
        }

        .gallery-nav{
            padding-top: 1.75rem;

            &-item{
                padding-right: .5rem;
            }

            .slick-arrow{
                border: none;
                background: none;
                position: absolute;
                font-weight: 300;
                color: #b58d58;
                outline: none;
                cursor: pointer;

                & > img{
                    width: 20px;
                }

                &-prev{
                    top: 0;
                    left: 0;

                    & > img{
                        margin-right: .75rem;
                    }
                }

                &-next{
                    top: 0;
                    right: 0;

                    & > img{
                        margin-left: .75rem;
                    }
                }
            }
        }

        .content{
            &-title{
                color: #3c3c3c;
                font-size: 2rem;
                font-weight: 400;
                margin-bottom: 2rem;
            }

            &-subtitle{
                color: #b58d58;
                font-size: 1.125rem;
                font-weight: 400;
                margin-bottom: 1.5rem;
            }
        }

        .btn-back{
            background: #b58d58;
            color: #fff;
            text-decoration: none;
            display: inline-block;
            padding: .75rem 1.5rem;
            margin-top: 24px;
            font-weight: 400;

            @include media-breakpoint-up(lg){
                margin-top: 50px;
            }
        }
    }
}