body.page-home{
    .pace {
        display: block;
    }

    .banner{
        &-item{
            height: calc(100vh - 260px);
            background: center center no-repeat;
            background-size: cover;

            @include media-breakpoint-up(lg){
                height: calc(100vh - 280px);
            }
            
            &--1{ 
                background-image:url('../assets/images/home/banner-1-xs.jpg'); 
                @include media-breakpoint-up(lg){
                    background-image:url('../assets/images/home/banner-1.jpg'); 
                }
            }
            &--2{ 
                background-image:url('../assets/images/home/banner-2-xs.jpg'); 
                @include media-breakpoint-up(lg){
                    background-image:url('../assets/images/home/banner-2.jpg'); 
                }
            }
            &--3{ 
                background-image:url('../assets/images/home/banner-3-xs.jpg'); 
                @include media-breakpoint-up(lg){
                    background-image:url('../assets/images/home/banner-3.jpg'); 
                }
            }
            &--4{ 
                background-image:url('../assets/images/home/banner-4-xs.jpg'); 
                @include media-breakpoint-up(lg){
                    background-image:url('../assets/images/home/banner-4.jpg'); 
                }
            }
        }

        .slick-dots{
            padding: 0;
            margin: 0;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            text-align: center;

            li{
                display: inline-block;
                list-style: none;
                margin: 1rem 10px;
                height: fit-content;

                &.slick-active{
                    button{
                        background: #fff;
                    }
                }

                button{
                    outline: none;
                    font-size: 0;
                    background: rgba(255, 255, 255, .7);
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    padding: 0;
                    border: none;

                    @include media-breakpoint-up(lg){
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }

    .intro{
        padding: 30px 0;

        &-text{
            text-align: center;
            color: #b58d58;
            font-size: 1.125rem;
        }

        .line{
            width: 100%;
            height: 2px;
            background: #e2e2e2;
            margin: 1rem auto;
            
            &:after{
                content: '';
                display: block;
                height: 2px;
                background: #b58d58;
                animation: Width100 6s infinite;
            }
        }

        .down{
            width: 30px;
            display: inline-block;
        }
    }

    .tile{
        background: #fff;

        &-item{
            &-visual{
                color: #fff;
                background: #b58d58;
                padding: 15px;
                font-size: 1rem;
                text-align: center;
                line-height: 1.1;

                @include media-breakpoint-up(lg){
                    font-size: 2rem;
                } 

                @include media-breakpoint-up(xl){
                    padding: 50px;
                } 

                &-text-top{
                    font-weight: lighter;
                }

                &-text-bottom{
                    line-height: 1.4;
                    font-size: 0.55rem;
                    font-weight: 700;
                    letter-spacing: 3px;
                    position: absolute;
                    left: 15px;
                    right: 15px;
                    bottom: 15px;
                    margin-bottom: 0;

                    @include media-breakpoint-up(md){
                        font-size: 1rem;
                    } 

                    @include media-breakpoint-up(xl){
                        font-size: 1.2rem;
                        left: 50px;
                        right: 50px;
                        bottom: 50px;
                    }   
                }

                &.tile-goodservice{
                    background: #093c71 url(../assets/images/home/tile-blue-flower.png) no-repeat center bottom;
                    background-size: 100% auto;
                }

                &.tile-safety{
                    background: #bf955a url(../assets/images/home/tile-gold-flower.png) no-repeat center bottom;
                    background-size: 100% auto;
                }
            }
            &-text{
                &-image{
                    display: block;
                    overflow: hidden;
                    padding-bottom: 40.57%;
                    position: relative;

                    &--aboutus{
                        padding-bottom: 40.57%;
                    }

                    &--md{
                        padding-bottom: 79.33%;
                    }

                    &--service{
                        padding-bottom: 91.52%;

                        @include media-breakpoint-up(lg){
                            padding-bottom: 41.94%;
                        }
                    }

                    &--serviceLogo{
                        background: #093c71;
                        height: 100%;
                        padding-bottom: 79.67%;

                        .visual{
                            min-width: 50% !important;
                            max-width: 50% !important;

                            &:hover, &:focus, &:active{
                                transform: translate(-50%, -50%) !important;
                            }
                        }
                    }

                    .visual{
                        left: 50%;
                        min-height: 100%;
                        min-width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: all .35s;

                        &:hover, &:focus, &:active{
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                    }
                }

                &-title{
                    color: #3c3c3c;
                    font-size: 1.5rem;
                    padding: 1.5rem 1rem 0;

                    @include media-breakpoint-up(lg){
                        padding-top: 2rem;
                    }
                }

                &-description{
                    color: #646464;
                    padding: 0 1rem;
                    margin-bottom: .5rem;
                    background: #fff;
                }

                &-link{
                    display: inline-block;
                    color: #b58d58;
                    text-decoration: none;
                    padding: 0 1rem 1rem;

                    &:hover, &:focus{
                        .tile-item-text-link-arrow{
                            transform: translateX(50%);
                        }
                    }

                    &-arrow{
                        margin-left: .5rem;
                        width: 24px;
                        height: 12px;
                        transition: all .35s;
                    }
                }
            }
        } 
    }

    .popup{
        background: #fff;

        @include media-breakpoint-up(lg){
            background: #E7E7E7;
        }
        &-cover{
            padding: 0;
            min-height: 48.67vw;
            background: center center no-repeat;
            background-size: cover;
            border-bottom: 5px solid #b58d58;

            @include media-breakpoint-up(lg){
                border-right: 5px solid #b58d58;
                border-bottom: none;
            }
        }

        &-text{
            padding: 2rem 15px;

            @include media-breakpoint-up(lg){
                padding: 130px 120px 20px;
                overflow-y: auto;
                height: 907px;
            }

            &-title{
                font-weight: 400;
                font-size: 2.125rem;
                margin-bottom: 1rem;
            }

            ol {
                padding-left: 1rem;
                counter-reset: item;

                li {
                    counter-increment: item;
                }

                ol > li {
                    display: block;

                    &:before {
                        content: counters(item, ".") ". ";
                        margin-left: -30px;
                    }
                }
            }
        }

        &-btn-close{
            @extend .d-flex, .align-items-center;
            color: #b58d58;
            text-decoration: none;
            font-weight: 400;
            margin-left: auto;
            display: block;
            width: fit-content;
            position: absolute;
            right: 2.125rem;
            top: 2.125rem;
        }
    }


    #popup-aboutus{
        .popup{
            &-cover{
                background: #fff;
            }
        }
        .popup-cover-slick{
            width: 100%;
            height: 100%;
            @include media-breakpoint-up(md){
                min-height: 49vw;
            }

            &-item{
                padding-bottom: 56.25%;
                background: center center no-repeat;
                height: 100%;
                min-height: 56.25vw;
                background-size: cover;
                margin-bottom: -7px;
                
                @include media-breakpoint-up(md){
                    min-height: 49vw;
                    margin-bottom: -7px;
                }
            }

            .slick-dots{
                padding: 0;
                margin: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;

                li{
                    display: inline-block;
                    list-style: none;
                    margin: 1rem 10px;
                    height: fit-content;

                    &.slick-active{
                        button{
                            background: #fff;
                        }
                    }

                    button{
                        outline: none;
                        font-size: 0;
                        background: rgba(255, 255, 255, .7);
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        padding: 0;
                        border: none;

                        @include media-breakpoint-up(lg){
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
            &-item{
                &.knowus{
                    background-image: url('../assets/images/home/popup-aboutus-mobile-1.jpg');

                    @include media-breakpoint-up(md){
                        // background-image: url(../assets/images/home/popup-md-1-2.jpg), url(../assets/images/home/popup-md-1-1.jpg);    /*incase 2 bg pics */
                        background-image: url('../assets/images/home/popup-aboutus-1.jpg');
                        // background-repeat: no-repeat;
                        // background-size: 100% auto, auto 80%;
                        // background-position: bottom center, top center;
                    }
                }

                &.buses{
                    background-image: url('../assets/images/home/popup-aboutus-mobile-2.jpg');

                    @include media-breakpoint-up(md){
                        background-image: url('../assets/images/home/popup-aboutus-2.jpg');
                    }
                }
            }
        }
    }
    
    #popup-md{
        .popup{
            &-cover{
                background: #fff;
            }
        }
        .popup-cover-slick{
            width: 100%;
            height: 100%;
            @include media-breakpoint-up(md){
                min-height: 56.25vw; /*48.67vw;*/
            }

            &-item{
                padding-bottom: 56.25%;
                background: center center no-repeat;
                height: 100%;
                min-height: 56.25vw;
                background-size: cover;
                margin-bottom: -7px;
                
                @include media-breakpoint-up(md){
                    min-height: 56.25vw; /*48.67vw;*/
                    margin-bottom: -7px;
                }
            }

            .slick-dots{
                padding: 0;
                margin: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;

                li{
                    display: inline-block;
                    list-style: none;
                    margin: 1rem 10px;
                    height: fit-content;

                    &.slick-active{
                        button{
                            background: #fff;
                        }
                    }

                    button{
                        outline: none;
                        font-size: 0;
                        background: rgba(255, 255, 255, .7);
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        padding: 0;
                        border: none;

                        @include media-breakpoint-up(lg){
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }

            &-item{
                &.md{
                    background-image: url('../assets/images/home/popup-md-mobile.jpg');
                    @include media-breakpoint-up(md){ /* first slide of md popup */
                        background-image: url(../assets/images/home/popup-md-1-2.jpg), url(../assets/images/home/popup-md-1-1.jpg);
                        background-repeat: no-repeat;
                        background-size: 100% auto, auto 80%;
                        background-position: bottom center, top center;
                    }
                }

                &.bus{ /* second slide of md popup */
                    background-image: url('../assets/images/home/popup-md-mobile2-2.jpg');

                    @include media-breakpoint-up(md){
                        background-image: url('../assets/images/home/popup-slide2-2.jpg'); 
                        
                    }
                }
            }
        }
    }

    #popup-service{
        .popup{
            &-cover{
                background: #fff;
            }
        }
        .popup-cover-slick{
            // ------------------------------------------- start popup slick 3 -------------------------------------------
            width: 100%;
            height: 100%;
            @include media-breakpoint-up(md){
                min-height: 50vw; /*48.67vw;*/
            }

            &-item{
                padding-bottom: 56.25%;
                background: center center no-repeat;
                height: 100%;
                min-height: 56.25vw;
                background-size: cover;
                margin-bottom: -7px;
                
                @include media-breakpoint-up(md){
                    min-height: 50vw; /*48.67vw;*/
                    margin-bottom: -7px;
                }
            }

            .slick-dots{
                padding: 0;
                margin: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;

                li{
                    display: inline-block;
                    list-style: none;
                    margin: 1rem 10px;
                    height: fit-content;

                    &.slick-active{
                        button{
                            background: #fff;
                        }
                    }

                    button{
                        outline: none;
                        font-size: 0;
                        background: rgba(255, 255, 255, .7);
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        padding: 0;
                        border: none;

                        @include media-breakpoint-up(lg){
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }
            // ------------------------------------------- end popup slick 3 -------------------------------------------
            
            &-item{
                &.item-1{
                    background-image: url('../assets/images/home/popup-service-mobile-1.jpg');

                    @include media-breakpoint-up(md){
                        background-image: url('../assets/images/home/popup-service-1.jpg');
                    }
                }
                &.item-2{
                    background-image: url('../assets/images/home/popup-service-mobile-2.jpg');

                    @include media-breakpoint-up(md){
                        background-image: url('../assets/images/home/popup-service-2.jpg');
                    }
                }
                &.item-3{
                    background-image: url('../assets/images/home/popup-service-mobile-3.jpg');

                    @include media-breakpoint-up(md){
                        background-image: url('../assets/images/home/popup-service-3.jpg');
                    }
                }
                &.item-4{
                    background-image: url('../assets/images/home/popup-service-mobile-4.jpg');

                    @include media-breakpoint-up(md){
                        background-image: url('../assets/images/home/popup-service-4.jpg');
                    }
                }
            }
        }
    }

    .feature{
        background: #f2f2f2;
        padding-bottom: 30px;
        padding-top: 50px;

        @include media-breakpoint-up(lg){
            padding-bottom: 40px;
            padding-top: 90px;
        }

        .header{
            font-size: 2rem;
            font-weight: 400;
            text-align: center;
            color: #093c71;
            margin-bottom: 25px;
            text-align: center;

            &:before{
                content: '';
                height: 5px;
                background: #bf955a;
                width: 110px;
                position: absolute;
                top: 100%;
                left: calc(50% - 55px);
            }
        }

        .featureSlide{
            margin-top: 0;
            padding-bottom: 70px;
            position: relative;

            @include media-breakpoint-up(lg){
                margin-top: 50px;
            }

            &__item{
                @extend .d-lg-flex, .align-items-start;

                &__visual{
                    padding: 45px 30%;
                    
                    img{
                        min-width: 140px;
                    }

                    @include media-breakpoint-up(lg){
                        padding: 0 30px;
                        
                    }
                }

                &__text{
                    &__title{
                        color: #002c5d;
                        font-size: 1.375rem;
                        font-weight: 600;
                        margin-bottom: 1rem;
                    }

                    &__description{
                        font-size: 1.125rem;
                    }
                }
            }

            .slick-arrow{
                cursor: pointer;
                position: absolute;
                bottom: 0;
                background: none;
                border: none;
                color: #a58353;
                font-size: 2rem;
                line-height: 1;
                @include media-breakpoint-up(lg){
                    font-size: 2.5rem;
                }

                &-prev{
                    left: 0;
                    @include media-breakpoint-up(lg){
                        left: 35%;
                    }
                }

                &-next{
                    right: 0;
                    @include media-breakpoint-up(lg){
                        right: 35%;
                    }
                }
            }

            .slick-dots{
                bottom: 0;
                left: 2rem;
                right: 2rem;
                position: absolute;
                padding: 0;
                text-align: center;
                margin: 0;

                @include media-breakpoint-up(lg){
                    left: calc(35%  + 2rem);
                    right: calc(35%  + 2rem);
                    bottom: 3px;
                }

                li{
                    cursor: pointer;
                    display: inline-block;
                    margin: 15px 6px;
                    text-indent: -200vw;
                    width: 10px;
                    height: 10px;
                    background: #a58353;
                    border-radius: 50%;
                    opacity: .4;
                    transition: all .7s;

                    @include media-breakpoint-up(lg){
                        margin: 15px;
                    }

                    &.slick-active{
                        opacity: 1;
                    }
                }
            }
        }
    }

    .clients{
        .header{
            font-size: 2rem;
            font-weight: 400;
        }

        .link{
            display: inline-block;
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .news{
        padding-top: 25px;
        position: relative;

        @include media-breakpoint-up(lg){
            padding-top: 80px;
        }

        .blue-bar{
            height: calc(80px + 2rem);
            width: 100%;
            background: #093c71;
            position: absolute;
            bottom: 0;
        }

        .header{
            font-size: 2rem;
            font-weight: 400;
            text-align: center;
            color: #b58d58;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg){
                margin-bottom: 50px;
            }
        }

        &-link-more{
            display: inline-block;
            margin-top: 1rem;
            color: #fff;
            text-decoration: none;

            &:hover, &:focus{
                .link-arrow{
                    transform: translateX(50%);
                }
            }

            .link-arrow{
                margin-left: .5rem;
                width: 24px;
                height: 12px;
                transition: all .35s;
            }
        }
    }
    
    // --------------------------- Start timeline --------------------------- 

    .container-timeline {
        max-width: 600px;
        width: 90%;
        margin: 0 auto;
        line-height: 1.5;
        
    }

    $border-color: rgba(#bf955a, .5);

    .timeline-item {
        padding: 2em 2em 0.5em;
        position: relative;
        // color: rgba(black, .7);
        border-left: 2px solid $border-color;
        
        p {
            font-size: 1rem;
        }
        
        &::before {
            content: attr(date-is);
            position: absolute;
            left: 2em;
            font-weight: bold;
            top: 0.6em;
            display: block;
            font-weight: 700;
            font-size: 1rem;
            color: #bf955a;
        }

        &::after {
            width: 10px;
            height: 10px;
            display: block;
            top: 1em;
            position: absolute;
            left: -7px;
            border-radius: 10px;
            content: '';
            border: 2px solid $border-color;
            background:#bf955a;
        }

        &:last-child {
            border-image: linear-gradient(
                to bottom,
                $border-color 10%,
                rgba($border-color, 0)) 1 100%
            ;
        }
    }
    // --------------------------- End of timeline --------------------------- 

    // // --------------------------- Start scrollbar --------------------------- 
    // // header
    // // {
    // //     font-family: 'Lobster', cursive;
    // //     text-align: center;
    // //     font-size: 25px;	
    // // }
    
    // #info
    // {
    //     font-size: 18px;
    //     color: #555;
    //     text-align: center;
    //     margin-bottom: 25px;
    // }
    
    // .scrollbar
    // {
    //     margin-left: 30px;
    //     float: left;
    //     height: 300px;
    //     width: 65px;
    //     // background: #F5F5F5;
    //     overflow-y: auto;
    //     margin-bottom: 25px;
    // }
    
    // .force-overflow
    // {
    //     min-height: 450px;
    // }
    
    // #scrollbar-wrapper
    // {
    //     // text-align: center;
    //     // width: 500px;
    //     margin: auto;
    // }
    
    // /*
    //  *  STYLE 1
    //  */
    
    // #style-1::-webkit-scrollbar-track
    // {
    //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //     border-radius: 10px;
    //     background-color: #F5F5F5;
    // }
    
    // #style-1::-webkit-scrollbar
    // {
    //     width: 12px;
    //     background-color: #F5F5F5;
    // }
    
    // #style-1::-webkit-scrollbar-thumb
    // {
    //     border-radius: 10px;
    //     -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    //     background-color: #555;
    // }
    
    // // --------------------------- End scrollbar --------------------------- 
    


}