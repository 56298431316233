.page-contact-form{
    .content-padder{
        margin-top: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-up(lg){
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    form.contact-form{
        .header{
            font-size: 2rem;
            color: #b58d58;
            margin-bottom: 25px;
        }

        input, textarea, select{
            border: 1px solid #b58d58 !important;
            border-radius: 0 !important;
            font-weight: 300;
            outline: none !important;

            &:focus{
                box-shadow: none;
            }

            &.form-control-file{
                padding: .5rem;
            }
        }

        .custom-file-label{
            border: 1px solid #b58d58 !important;
            border-radius: 0;

            &:after{
                background: #b58d58;
                border-radius: 0;
                content: "แนบไฟล์";
                color: #fff;
            }
        }

        .custom-select{
            background: url('../assets/images/icons/dropdown.png') no-repeat right;
            background-size: auto 100%;
        }

        label{
            color: #b58d58;
            font-weight: 400;
        }

        .remark{
            font-size: .85rem;
            color: #b58d58;
        }

        button{
            width: auto;
            border: none;
            background-color: #b58d58;
            background-image: url(../assets/images/icons/link-arrow-white.svg);
            background-repeat: no-repeat;
            background-size: auto 30%;
            background-position: right 15% top 55%;
            color: #fff;
            font-weight: 400;
            padding: .5rem 3rem;
            border-radius: 0;
            display: inline-block;
            outline: none !important;
            margin-bottom: 25px;
            transition: all .4s;
            text-indent: -30%;

            &:hover{
                background-position: right .5rem top 55%;
            }

            &:focus{
                box-shadow: none;
            }

            @include media-breakpoint-up(lg){
                margin-bottom: 50px;
            }
        }
    }

    .info{
        background: url(../assets/images/contact/callcenter.png) no-repeat #f2f2f2;
        background-position: right 15px top 15px;
        background-size: 45% auto;
        padding: 15% 1rem 1rem;

        @include media-breakpoint-up(md){
            background-size: 30% auto;
        }

        @include media-breakpoint-up(lg){
            padding-top: 1rem;
            background-size: 30% auto;
        }
        .header{
            margin-top: 1rem;
            font-size: 1.375rem;
            color: #b58d58;
            font-weight: 300;
        }

        .text{
            color: #b58d58;
        }

        .line{
            height: 1px;
            background: #b58d58;
            margin: 1.75rem auto;
        }

        .office-hour{
            color: #646464;
        }

        .telephone{
            color: #646464;
            display: flex;
            flex-wrap: wrap;

            &-itemInter{
                display: inline-block;
                color: #646464;
                text-decoration: none;
                padding-left: 1.5rem;
                background: url(../assets/images/icons/phone-gold.svg) left center no-repeat;
                background-size: auto 80%;
                margin-bottom: .75rem;
                flex: 1 0 50%;
            }
            &-item{
                display: inline-block;
                color: #646464;
                text-decoration: none;
                padding-left: 1.5rem;
                background: url(../assets/images/icons/Call_Brown.svg) left center no-repeat;
                background-size: auto 80%;
                margin-bottom: .75rem;
                flex: 1 0 50%;
            }
        }
        

        .social{
            display: flex;
            flex-wrap: wrap;

            &-item{
                color: #646464;
                text-decoration: none;
                padding: .25rem 0;
                display: inline-block;
                flex: 1 0 100%;
                font-size: 1rem;

                &-icon{
                    margin-right: .5rem;
                    width: 1rem;
                }
            }
        }

        .address{
            color: #646464;
        }
    }
}