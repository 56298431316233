/*!
 * Project: Project Name
 * Author: Author Name, E-mail, Website
 */

/**
 * Styles via ITCSS Methodology
 *
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported to ensure the consistent increase of specificity.
 *
 *  1. Settings...........Global configuration and variables
 *  2. Tools..............Functions and mixins
 *  3. Generic............Ground zero styles (normalizing rules, box-sizing etc...)
 *  4. Elements...........Unclassed (bare) HTML elements (like H1, Ul, A etc...)
 *  5. Objects............Common non-cosmetic structural design patterns
 *  6. Components.........Specific cosmetic elements of UI
 *  7. Utilities..........Helpers and overrides
 *
 *     Modules............Multi-part components e.g. Navbar (HTML, CSS and JS).
 *     Shame..............All the CSS declarations, hacks and things we are not proud of.
 */

@import "settings/_colors.scss";
@import "settings/_global.scss";
@import 'bootstrap/scss/bootstrap';
@import "tools/_mixins.scss";
@import "generic/_bootstrap.grid.scss";
@import "generic/_box-sizing.scss";
@import "generic/_normalize.scss";
@import "generic/_print.scss";
@import "elements/_headings.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_media.scss";
@import "elements/_page.scss";
@import "elements/_paragraphs.scss";
@import "elements/_tables.scss";
@import "objects/_list-inline.scss";
@import "objects/_list-unstyled.scss";
@import "objects/_section.scss";
@import "components/_buttons.scss";
@import "components/_footer.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_visibility.scss";
@import "C:/repo/subcharoen-dev-pk-ver/src/modules/carousel/carousel.scss";
@import "C:/repo/subcharoen-dev-pk-ver/src/modules/navbar/navbar.scss";
@import 'shame';
@import 'slick-carousel/slick/slick.scss';
@import 'jquery-nice-select/scss/nice-select';

.no-gutter{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ellipsis{
    overflow: hidden;
    position: relative;
    line-height: 1.5em;
    max-height: 4.5em;
    text-align: left;
    margin-right: -.5em;
    padding-right: 1em;
    background: inherit;
    width: 100%;

    &:before{
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &:after{
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: .2em;
        background: inherit;
    }

    &-1{
        max-height: 1.5em;
    }

    &-2{
        max-height: 3em;
    }

    &-3{
        max-height: 4.5em;
    }

    &-4{
        max-height: 6em;
    }
}
.ellipsis-1{
    @extend .ellipsis;
    max-height: 1.5em;
}

.ellipsis-2{
    @extend .ellipsis;
    max-height: 3em;
}

.ellipsis-3{
    @extend .ellipsis;
    max-height: 4.5em;
}

.ellipsis-4{
    @extend .ellipsis;
    max-height: 6em;
}

.icon{
    &-close{
        width: 1.375rem;
        height: 1.375rem;
        display: inline-block;
        background: url(../assets/images/icons/btn-close.svg) center center no-repeat;
        background-size: contain;
    }
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Width100 {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

.pace {
    display: none;
    pointer-events: none;
    user-select: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, #031e44 0%, #093c71 50%, #031e44 100%);
    background-size: 400% 400%;
    z-index: 2000;
    animation: Gradient 10s ease infinite;

    &:after{
        content: '';
        width: 50vw;
        height: 28.375vw;
        background: url(../assets/images/logo-loading.svg) center center no-repeat;
        background-size: contain;
        position: fixed;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-up(lg){
            width: 198px;
            height: 112px;
        }
    }

    &-progress {
        background: #b58d58;
        position: fixed;
        z-index: 2000;
        bottom: 0;
        right: 100%;
        width: 100%;
        height: 8px;
    }
}

body.overlap-nav{
    @include media-breakpoint-up(lg){
        .navbar-bottom-bar{
            height: 40px;
            padding-top: 85px;
            position: relative;

            &:before{
                content: '';
                position: absolute;
                background: #031e44;
                height: 50px;
                display: block;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        .overlap-nav-pullup{
            transform: translateY(-85px);
            margin-bottom: -25px;
        }
    }

    .overlap-nav-pullup{
        > .row{
            > [class^=col-]{
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-bottom: 2rem;

                @include media-breakpoint-up(lg){
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }
        }
    }
}

.news-item{
    margin-bottom: 1rem;

    &:hover{
        .news-item-image .image{
            background-size: 110% auto;
        }
    }

    &-number{
        font-size: 1.5rem;
        font-weight: bold;
        color: #b58d58;
        position: relative;
        display: inline-block;

        &:after{
            content: '';
            height: 2px;
            width: 75px;
            background: #b58d58;
            top: calc(50% - 1px);
            left: calc(100% + 5px);
            position: absolute;
        }
    }

    &-title{
        text-align: center;
        font-size: 1.375rem;
        margin: 0 auto;
        font-weight: 400;
    }

    &-subtitle{
        text-align: center;
        font-size: 1.125rem;
    }

    &-image{
        padding: 0 10px;
        position: relative;

        a.image{
            display: block;
            width: 100%;
            padding-bottom: 62.12%;
            background: center center no-repeat;
            background-size: 100% auto;
            position: relative;
            z-index: 10;
            transition: all .35s;
        }

        &:before{
            content: '';
            background: #f3f3f3;
            position: absolute;
            top: 50%;
            left: 0;
            bottom: 0;
            width: 100%;
        }

        &:after{
            content: '';
            border: 15px solid #b58d58;
            position: absolute;
            left: 10px;
            top: 0;
            z-index: 11;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    &-text{
        padding: 20px 10px 0;
        background: #f3f3f3;
        margin-bottom: 0;

        &:last-child{
            padding-bottom: 40px;
        }
    }

    &-link{
        display: inline-block;
        color: #b58d58;
        text-decoration: none;

        &:hover, &:focus{
            color: #b58d58;
            text-decoration: none;
            .link-arrow{
                transform: translateX(50%);
            }
        }

        .link-arrow{
            margin-left: .5rem;
            width: 24px;
            height: 12px;
            transition: all .4s;
        }
    }
}

.clients{
    padding-top: 45px;
    background-color: #f3f3f3;
    background-image: url(../assets/images/customer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    @include media-breakpoint-up(lg){
        background-size: 100% auto;
        background-position: center top -10%;
    }

    @include media-breakpoint-up(xl){
        background-size: 100% auto;
        background-position: center bottom 10%;
    }

    .header{
        padding: 35px 0;
        text-align: center;
        color: #fff;
        background: url(../assets/images/logo-fade-10.svg) center center no-repeat;
        background-size: auto 60%;
        position: relative;
        margin-bottom: 30px;
        font-weight: 400;

        &:before{
            content: '';
            height: 5px;
            background: #bf955a;
            width: 110px;
            position: absolute;
            top: 100%;
            left: calc(50% - 55px);
        }

        .gold{
            color: #bf955a;
        }
    }

    .text{
        color: #fff;
    }

    .logo-wrapper{
        text-align: center;
        .logo{
            position: relative;
            padding-bottom: 25%;
            background-repeat: no-repeat;
            background-position: center center;
            width: 32%;
            display: inline-block;

            @include media-breakpoint-up(lg){
                width: 15.5%;
            }
        }
    }

    .people{
        position: relative;

        .slick{
            &-arrow{
                position: absolute;
                top: calc(50% - 1rem);
                color: #000;
                background: transparent;
                border: none;
                z-index: 10;
                font-size: 2rem;
                line-height: 1;
                outline: none;
                cursor: pointer;

                &-prev{
                    left: -1.5rem;
                }

                &-next{
                    right: -1.5rem;
                }
            }
            &-track{
                @extend .d-flex, .align-items-stretch;
            }

            &-slide{
                @include media-breakpoint-up(lg){
                    margin: 0 15px;
                }
            }
        }

        .review{
            background: #fff;
            width: 100%;
            display: inline-block;
            position: relative;
            box-shadow: 4px 5px 9px -3px rgba(0,0,0,0.26);

            @include media-breakpoint-up(lg){
                width: calc(50% - 60px);
            }

            &-text{
                padding: 2.25rem 1rem 3.75rem;
                border-bottom: 6px solid #093c71;

                @include media-breakpoint-up(lg){
                    padding: 2.125rem 2.25rem 2.75rem;
                }

                &-name{
                    color: #a58353;
                    margin-bottom: 0;
                    font-size: 1.375rem;
                }

                &-title{
                    color: #a58353;
                    font-size: .9rem;
                    margin-bottom: 1rem;
                }

                &-comment{
                    transition: all 1s;
                }
            }

            &-avatar{
                @extend .rounded-circle;
                border: 4px solid #fff;
                position: absolute;
                box-sizing: border-box;
                width: 94px;
                left: calc(50% - 44px);
                bottom: calc(3rem - 31px);
                filter: grayscale(100%);
                
                @include media-breakpoint-up(lg){
                    width: 86px;
                    left: calc(50% - 43px);
                    bottom: calc(3rem - 35px);
                }
            }

            &-star{
                background-color: #a58353;
                text-align: right;
                padding: 1rem;
                line-height: 1;

                i{
                    width: .85rem;
                    height: .85rem;
                    display: inline-block;
                    background: center center no-repeat;
                    background-size: contain;

                    @include media-breakpoint-up(lg){
                        width: 1rem;
                        height: 1rem;
                    }

                    &.review-star-fill{
                        background-image: url(../assets/images/home/star-fill.svg);
                    }

                    &.review-star-blank{
                        background-image: url(../assets/images/home/star-blank.svg);
                    }
                }
            }
        }

        .link{
            text-decoration: none;
            color: #a58353;

            &:hover, &:focus{
                .link-arrow{
                    transform: translateX(50%);
                }
            }

            .link-arrow{
                margin-left: .5rem;
                width: 24px;
                height: 12px;
                transition: all .4s;
            }
        }
    }
}

@import "pages/_bus.scss";
@import "pages/_contact_form.scss";
@import "pages/_contact.scss";
@import "pages/_customer.scss";
@import "pages/_index.scss";
@import "pages/_news_detail.scss";
@import "pages/_news.scss";