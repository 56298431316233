.page-news{
    .header{
        color: #a58353;
        font-size: 2.125rem;
        position: relative;
        margin-bottom: 60px;

        .text-wrap{
            background: #fff;
            padding-right: .75rem;
            display: inline-block;
            position: relative;
            z-index: 2;
        }

        &:before{
            content: '';
            background-color: #a58353;
            position: absolute;
            height: 2px;
            width: 100%;
            top: calc(50% - 1px);
            z-index: 1;
        }
    }

    .news-item{
        margin-bottom: 40px;
    }

    .banner{
        .banner-items{
            position: relative;
            border: 7px solid #a58353;
            border-left-width: 0;
            border-right-width: 0;
            border-top-width: 0;

            @include media-breakpoint-up(lg){
                border: 7px solid #a58353;
            }

            &:before{
                display: none;

                @include media-breakpoint-up(lg){
                    display: block;
                    content: '';
                    width: 45px;
                    border: 45px solid #a58353;
                    border-right-color: transparent;
                    border-bottom-color: transparent;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 5;
                }
            }

            .slick-dots{
                padding: 0;
                margin: 0;
                position: absolute;
                right: 1rem;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;

                @include media-breakpoint-up(lg){
                    top: unset;
                    transform: none;
                    left: 0;
                    right: 0;
                    bottom: 1.375rem;
                }

                li{
                    display: block;
                    list-style: none;
                    height: fit-content;
                    margin: .25rem 0;

                    @include media-breakpoint-up(lg){
                        display: inline-block;
                        margin: 1rem .5rem;
                    }

                    &.slick-active{
                        button{
                            background: #fff;
                        }
                    }

                    button{
                        outline: none;
                        font-size: 0;
                        background: rgba(255, 255, 255, .7);
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        padding: 0;
                        border: none;

                        @include media-breakpoint-up(lg){
                            width: 12px;
                            height: 12px;
                        }
                    }
                }
            }

            .banner-item{
                padding-bottom: 29.674%;    
                background-color: #fff;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                display: block;
                position: relative;
                margin-bottom: -8px;

                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 50px;
                    background: linear-gradient(to bottom, rgba(76,76,76,0) 0%, rgba(19,19,19,1) 100%);
                }

                &:hover, &:focus, &:active{
                    .banner-item-copy{
                        &:after{
                            transform: translateX(1rem);
                        }
                    }
                }

                &-copy{
                    color: #fff;
                    line-height: 1.1;
                    font-weight: 600;
                    width: fit-content;
                    padding-right: 1rem;
                    position: absolute;
                    bottom: 1rem;
                    left: 1.375rem;
                    z-index: 10;

                    &:after{
                        content: '';
                        width: 24px;
                        height: 14px;
                        background: center center no-repeat url(../assets/images/icons/link-arrow-white.svg);
                        background-size: contain;
                        position: absolute;
                        left: 100%;
                        top: 50%;
                        transition: .4s all;
                    }

                    &-title{
                        font-size: 1.375rem;
                        margin-bottom: 0;
                    }

                    &-subtitle{
                        margin-bottom: 0;
                        font-size: 1.125rem;
                    }
                }
            }
        }

        &-mask{
            display: none;

            @include media-breakpoint-up(lg){
                text-align: center;
                color: #fff;
                position: absolute;
                right: 22px;
                bottom: 7px;
                display: block;
                width: fit-content;
                padding: 1.5rem 1rem;
                line-height: 1.2;
                
                &:before{
                    content: '';
                    width: 220px;
                    border: 110px solid #a58353;
                    border-left-color: transparent;
                    border-top-color: transparent;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                }

                span{
                    display: block;
                    position: relative;
                    z-index: 2;
                    font-size: 1.375rem;
                    letter-spacing: 1px;

                    &.bold{
                        letter-spacing: 3px;
                        font-size: 1.75rem;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .load-more{
        color: #a58353;
        text-decoration: none;
        line-height: 2rem;
        padding-left: 3rem;
        background: url(../assets/images/icons/circle-down.svg) left center no-repeat;
        background-size: contain;
        font-size: 1.375rem;
    }
}